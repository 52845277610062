import React from 'react';
import { Link, graphql } from 'gatsby';

import Img from 'gatsby-image';
import ReactAudioPlayer from 'react-audio-player';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const { previous, next } = this.props.pageContext;
    const featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;

    const metaTitle = post.frontmatter.seoTitle ? post.frontmatter.seoTitle : post.frontmatter.title;
    const postTitle = post.frontmatter.episode
      ? `Episode ${post.frontmatter.episode} - ${post.frontmatter.title}`
      : post.frontmatter.title;

    return (
      <Layout>
        <SEO
          title={metaTitle}
          isExactTitle={!!post.frontmatter.seoTitle}
          description={post.frontmatter.description || post.excerpt}
          featuredImage={(post.frontmatter.featuredImage && post.frontmatter.featuredImage.publicURL) || ''}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {postTitle}
            </h1>

            <p
              style={{
                ...scale(-1 / 5),
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
              {post.frontmatter.date}
            </p>
            <Img fluid={featuredImgFluid} />
          </header>

          <section>
            <ReactAudioPlayer src={post.frontmatter.enclosure.publicURL} autoPlay={false} controls />
          </section>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query AudioPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        enclosure {
          publicURL
        }
        episode
        seoTitle
      }
    }
  }
`;
